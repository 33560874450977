import plugin from 'tailwindcss/plugin'

const systemFonts = [
  '-apple-system, system-ui',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif'
]

export default {
  corePlugins: { preflight: false }, // to prevent removing old frontend (v1) styles
  theme: {
    extend: {
      colors: {
        // Brand Colors
        'brand-900': 'var(--brand-900)',
        'brand-800': 'var(--brand-800)',
        'brand-700': 'var(--brand-700)',
        'brand-600': 'var(--brand-600)',
        'brand-500': 'var(--brand-500)',
        'brand-400': 'var(--brand-400)',
        'brand-300': 'var(--brand-300)',
        'brand-200': 'var(--brand-200)',
        'brand-100': 'var(--brand-100)',
        'brand-50': 'var(--brand-50)',
        // Primary Colors (Legacy: it is renamed to "brand" starting from 24.09.14)
        // https://bookipi.slack.com/archives/C055JM3SWTZ/p1726731845512949
        'primary-900': 'var(--primary-900)',
        'primary-800': 'var(--primary-800)',
        'primary-700': 'var(--primary-700)',
        'primary-600': 'var(--primary-600)',
        'primary-500': 'var(--primary-500)',
        'primary-400': 'var(--primary-400)',
        'primary-300': 'var(--primary-300)',
        'primary-200': 'var(--primary-200)',
        'primary-100': 'var(--primary-100)',
        'primary-50': 'var(--primary-50)',
        // Default Colors
        'gray-900': '#111928',
        'gray-800': '#1F2A37',
        'gray-700': '#374151',
        'gray-600': '#4B5563',
        'gray-500': '#6B7280',
        'gray-400': '#9CA3AF',
        'gray-300': '#D1D5DB',
        'gray-200': '#E5E7EB',
        'gray-100': '#F3F4F6',
        'gray-50': '#F9FAFB',
        'red-900': '#771D1D',
        'red-800': '#9B1C1C',
        'red-700': '#C81E1E',
        'red-600': '#E02424',
        'red-500': '#F05252',
        'red-400': '#F98080',
        'red-300': '#F8B4B4',
        'red-200': '#FBD5D5',
        'red-100': '#FDE8E8',
        'red-50': '#FDF2F2',
        'yellow-900': '#633112',
        'yellow-800': '#723B13',
        'yellow-700': '#8E4B10',
        'yellow-600': '#9F580A',
        'yellow-500': '#C27803',
        'yellow-400': '#E3A008',
        'yellow-300': '#FACA15',
        'yellow-200': '#FCE96A',
        'yellow-100': '#FDF6B2',
        'yellow-50': '#FDFDEA',
        'warning-900': '#7A2E0E',
        'warning-800': '#93370D',
        'warning-700': '#B54708',
        'warning-600': '#DC6803',
        'warning-500': '#F79009',
        'warning-400': '#FDB022',
        'warning-300': '#FEC84B',
        'warning-200': '#FEDF89',
        'warning-100': '#FEF0C7',
        'warning-50': '#FFFAEB',
        'warning-25': '#FFFCF5',
        'green-900': '#014737',
        'green-800': '#03543F',
        'green-700': '#046C4E',
        'green-600': '#057A55',
        'green-500': '#0E9F6E',
        'green-400': '#31C48D',
        'green-300': '#84E1BC',
        'green-200': '#BCF0DA',
        'green-100': '#DEF7EC',
        'green-50': '#F3FAF7',
        'blue-900': '#233876',
        'blue-800': '#1E429F',
        'blue-700': '#1A56DB',
        'blue-600': '#1C64F2',
        'blue-500': '#3F83F8',
        'blue-400': '#76A9FA',
        'blue-300': '#A4CAFE',
        'blue-200': '#C3DDFD',
        'blue-100': '#E1EFFE',
        'blue-50': '#EBF5FF',
        'indigo-900': '#362F78',
        'indigo-800': '#42389D',
        'indigo-700': '#5145CD',
        'indigo-600': '#5850EC',
        'indigo-500': '#6875F5',
        'indigo-400': '#8DA2FB',
        'indigo-300': '#B4C6FC',
        'indigo-200': '#CDDBFE',
        'indigo-100': '#E5EDFF',
        'indigo-50': '#F0F5FF',
        'purple-900': '#4A1D96',
        'purple-800': '#5521B5',
        'purple-700': '#6C2BD9',
        'purple-600': '#7E3AF2',
        'purple-500': '#9061F9',
        'purple-400': '#AC94FA',
        'purple-300': '#CABFFD',
        'purple-200': '#DCD7FE',
        'purple-100': '#EDEBFE',
        'purple-50': '#F6F5FF',
        'pink-900': '#751A3D',
        'pink-800': '#99154B',
        'pink-700': '#BF125D',
        'pink-600': '#D61F69',
        'pink-500': '#E74694',
        'pink-400': '#F17EB8',
        'pink-300': '#F8B4D9',
        'pink-200': '#FAD1E8',
        'pink-100': '#FCE8F3',
        'pink-50': '#FDF2F8',
        'orange-900': '#771D1D',
        'orange-800': '#8A2C0D',
        'orange-700': '#B43403',
        'orange-600': '#D03801',
        'orange-500': '#FF5A1F',
        'orange-400': '#FF8A4C',
        'orange-300': '#FDBA8C',
        'orange-200': '#FCD9BD',
        'orange-100': '#FEECDC',
        'orange-50': '#FFF8F1',
        'teal-900': '#014451',
        'teal-800': '#05505C',
        'teal-700': '#036672',
        'teal-600': '#047481',
        'teal-500': '#0694A2',
        'teal-400': '#16BDCA',
        'teal-300': '#7EDCE2',
        'teal-200': '#AFECEF',
        'teal-100': '#D5F5F6',
        'teal-50': '#EDFAFA',
        // old colors
        primary: '#4DAAA9',
        primary20: '#DCEEEE',
        primary30: '#CAE6E5',
        primary110: '#459998',
        primary120: '#3E8887',
        nPrimaryDark: '#3E8887',
        nBackgroundSecondary: '#F7F8F8',
        //primary is getting overriden on main.css,
        primary500: '#4DAAA9',
        primary700: 'rgb(42, 189, 188)',
        primaryLight: '#EAF7F7',
        primaryDark: '#A9D2D2',
        primaryLabel: '#009688',
        newPrimary50: '#EEFCFB',
        newPrimary100: '#E9F8FB',
        newPrimary200: '#C5E7E6',
        newPrimary500: '#4DAAA9',
        newPrimary600: '#2CB2AD',
        newPrimary700: '#05A09B',
        newPrimary800: '#1F6E6C',
        contentSecondary: '#3E4B5B',
        contentInversePrimary: '#FFFFFF',
        backgroundSecondary: '#ecf0f1',
        positive100: '#E4F2E1',
        positive200: '#E0F3E0',
        positive400: '#4CA730',
        positive500: '#45A810',
        negative400: '#FE7F6E',
        negative500: '#DE4437',
        negative600: '#ED1C24',
        modalOverlayLight: 'rgba(0, 0, 0, 0.2)',
        modalOverlayDark: 'rgba(0, 0, 0, 0.6)',
        modalBorder: 'rgba(0, 0, 0, 0.2)',
        cardBorder: '#DDD9D5',
        grayBorder: '#C6C6C6',
        nGrayBorder: '#F0F0F1',
        periodBorder: '#A8A8A8',
        linkColor: '#f79421',
        editColor: '#4DAAA9',
        viewColor: '#4A7BF7',
        sendColor: '#57B038',
        reportColor: '#4DAAA9',
        emphasize: '#0A3A60',
        grey: '#B2B4BB',
        grey10: '#F7F8F8',
        grey20: '#F0F0F1',
        grey50: '#F9FAFB',
        grey120: '#93969F',
        grey150: '#656976',
        grey100: '#F3F4F6',
        grey200: '#E5E7EB',
        grey300: '#D1D5DB',
        grey400: '#9CA3AF',
        grey500: '#6B7280',
        grey900: '#111928',
        green100: '#DEF7EC',
        green800: '#03543F',
        red: '#F2545B',
        red50: '#FDF2F2',
        red110: '#DA4C52',
        red500: '#F05252',
        red700: '#C81E1E',
        red800: '#9B1C1C',
        orange100: '#FEECDC',
        orange800: '#8A2C0D',
        purple100: '#EDEBFE',
        purple800: '#5521B5',
        black: '#040607',
        inputLabelGray: '#111928',
        inputTextGray: '#6B7280',
        inputBorderGray: '#D1D5DB',
        inputBgGray: '#F9FAFB',
        alertSuccess: '#36B24D',
        // Signal Colors (to be added more)
        'success-500': '#12B76A',
        'warning-300': '#FEC84B',
        'error-500': '#F04438'
      },
      boxShadow: {
        card: '2px 2px 10px rgba(0, 0, 0, .08)',
        modal: '0px 25px 65px 0px rgba(15, 24, 33, 0.29)',
        alert: '5px 5px 10px rgba(0, 0, 0, .3)',
        md: '0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)',
        'lg-modal': '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
      },
      fontFamily: {
        sans: ['var(--font-sans)', ...systemFonts],
        sansPop: ['Poppins', ...systemFonts],
        sansAven: ['Avenir Next W01', 'Proxima Nova W01', 'Rubik', ...systemFonts],
        sansRubik: ['Rubik', ...systemFonts],
        sansWork: ['Work Sans', ...systemFonts],
        avenirNext: ['Avenir Next', ...systemFonts],
        inter: ['Inter', ...systemFonts]
      },
      fontSize: {
        'p-xs': '13px',
        'p-sm': '14.4px'
      },
      spacing: {
        0.1: '1px',
        0.5: '2px',
        1.5: '10px',
        3.5: '14px',
        modalTopSpace: '28px'
      },
      zIndex: {
        popover: '99999999',
        alert: '105000001', // same as the old angular-ui-notification
        modal: '105000001',
        dialog: '105000001',
        portal: '105000002', // for AlignPortal, it should be higher than modal&dialog
        tsrMobile: '4' // should lower than the mobile menu z-index 5 (.menu-mobile)
      },
      borderWidth: {
        0.5: '0.5px'
      },
      screens: {
        xl: { max: '1650px' },
        lg: { max: '1400px' },
        md: { max: '768px' },
        sm: { max: '576px' },
        xs: { max: '375px' }
      },
      keyframes: {
        'slide-in-down': {
          '0%': { opacity: 0, maxHeight: 0, overflow: 'hidden' },
          '100%': { opacity: 1, maxHeight: 200, overflow: 'visible' } // if we use maxHeight, we don't need to know actual height of target.
        },
        'fade-out': {
          from: { opacity: '1' },
          to: { opacity: '0' }
        }
      },
      animation: {
        'slide-in-down': 'slide-in-down .63s ease-in-out both alternate',
        'fade-out': 'ease-out duration-300'
      },
      backgroundImage: {
        'diagonal-stripe': `linear-gradient(
          135deg,
          #f0f0f0 25%,
          #ffffff 25%,
          #ffffff 50%,
          #f0f0f0 50%,
          #f0f0f0 75%,
          #ffffff 75%,
          #ffffff 100%
        )`
      },
      backgroundSize: {
        'size-base': '32px 32px'
      }
    }
  },
  plugins: [
    plugin(function ({ addComponents, addUtilities, theme }) {
      addComponents({
        '.textButton': {
          fontSize: theme('fontSize.sm'),
          fontWeight: theme('fontWeight.medium'),
          color: theme('colors.primary-700'),
          lineHeight: theme('lineHeight.none')
        },
        '.label-title': {
          fontSize: '24px',
          color: theme('colors.black'),
          fontFamily: theme('fontFamily.sansPop'),
          lineHeight: '1.4em',
          fontWeight: 600
        },
        '.label-subtitle': {
          fontSize: '18px',
          fontWeight: 500,
          color: theme('colors.black'),
          fontFamily: theme('fontFamily.sansPop'),
          lineHeight: '1.4em'
        },
        '.label-bold': {
          fontSize: '14px',
          fontWeight: 600,
          color: theme('colors.black'),
          fontFamily: theme('fontFamily.sansPop'),
          lineHeight: '1.4em'
        },
        '.label-paragraph': {
          fontSize: '14px',
          color: theme('colors.black'),
          fontFamily: theme('fontFamily.sansPop'),
          lineHeight: '1.4em'
        },
        '.label-small': {
          fontSize: '12px',
          color: theme('colors.black'),
          fontFamily: theme('fontFamily.sansPop'),
          lineHeight: '1.4em'
        },
        '.label-link': {
          fontFamily: theme('fontFamily.sansPop'),
          color: theme('colors.primary110'),
          cursor: 'pointer',
          lineHeight: '1.4em',
          fontSize: '14px',
          textDecorationLine: 'underline'
        },
        '.new-confirm-btn': {
          fontFamily: theme('fontFamily.sansPop'),
          backgroundColor: theme('colors.primary'),
          color: theme('colors.contentInversePrimary')
        },
        '.new-default-btn': {
          fontFamily: theme('fontFamily.sansPop'),
          backgroundColor: theme('colors.backgroundSecondary'),
          color: theme('colors.contentSecondary') + ' !important'
        }
      })
      addUtilities({
        '.hide-scrollbar::-webkit-scrollbar': {
          display: 'none'
        },
        '.hide-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        },
        '.no-appearance': {
          '-webkit-appearance': 'none !important'
        },
        '.inherit-height': {
          height: 'inherit'
        },
        '.desktopView': {
          "@media (max-width: theme('screens.md.max'))": {
            display: 'none'
          }
        },
        '.mobileView': {
          display: 'none',
          "@media (max-width: theme('screens.md.max'))": {
            display: 'block'
          }
        },
        '.white-checkmark': {
          appearance: 'none'
        },
        '.white-checkmark:checked': {
          'border-color': `transparent`,
          'background-color': `currentColor`,
          'background-size': `100% 100%`,
          'background-position': `center`,
          'background-repeat': `no-repeat`,
          'background-image': `url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='20' height='20' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E")`
        }
      })
    })
  ]
}
