export const PAYRUN_LENGTH_FOR_GOOGLE_REVIEW = 4
export const PAYRUN_LENGTH_FOR_ANNUAL_DISCOUNT = 1

export const DEFAULT_COMPANY_NAME = 'Your company name' // default value of the company field (from the mongo DB)

export const ENABLE_SPACESHIP = false

export const GB_FEATURES = [
  'checklist',
  'sendsuperform',
  'new_paywall',
  'new_employee_creation',
  'delayed_tokenisation',
  'refer_and_earn',
  'sandbox_env',
  'dynamic_banner',
  'agent_program_banner',
  'paywall_no_lite',
  'dummy_company_abn',
  'delayed_paywall',
  'autoclick_company_setup',
  'annual_plan_switch',
  'tsr_view',
  'ui_revamp_display',
  'optin_v2_banner',
  'people_revamp_display',
  'agent_revamp_full_rollout',
  'price_lock_in',
  'bds_nav_bar_v1',
  'discount_cancellation',
  'settings_revamp_display',
  'report_revamp_display',
  'super_revamp_display',
  'xero_import_opt_in',
  'opt_in_pay_run_automation',
  'bp:ai_roster_early_access',
  'bp:ai_roster_existing_users',
  'tsr:unpaid_leave',
  'tsr:unavailability_time_off'
]
const SEO_REDIRECTS = {
  'home.main': ['/ufoqlvmnobwht.html', '/ceadiygukeazgfka.html', '/doanetsaejeu.html'],
  tryDemo: ['/try-demo/'],
  forgotPassword: ['/forgot-password/'],
  login: ['/signup/', '/login/']
}

export function getRedirectFromPathname(pathname) {
  // find redirect from the SEO_REDIRECTS
  for (const [redirect, paths] of Object.entries(SEO_REDIRECTS)) {
    if (paths.includes(pathname)) {
      return redirect
    }
  }
}

export const ONBOARDING_VIDEOS = {
  compnaySetup: {
    url: 'https://www.youtube.com/embed/svKCC3N-PSo',
    eventName: 'Clicked onboarding tutorial 1 (complete your company setup)'
  },
  employeeSetup: {
    url: 'https://www.youtube.com/embed/qdAUa5dnNnc',
    eventName: 'Clicked onboarding tutorial 2 (add an employee)'
  },
  payRunSetup: {
    url: 'https://www.youtube.com/embed/-mn2HjQY8KY',
    eventName: 'Clicked onboarding tutorial 3 (create a pay run)'
  }
}

export const isTsrOnly = process.env.APP === 'people'
